const IconColor = '#718096'

const ShadowStyle = `
  0.5px 0.5px var(--shadowLight),
  1px 1px var(--shadowLight),
  1.5px 1.5px var(--shadowLight),
  2px 2px var(--shadowLight),
  2.5px 2.5px var(--shadowLight),
  3px 3px var(--shadowLight),
  3.5px 3.5px var(--shadowLight),
  4px 4px var(--shadowLight)
`
const ShadowAltStyle = `
  0.5px 0.5px var(--shadowDark),
  1px 1px var(--shadowDark),
  1.5px 1.5px var(--shadowDark),
  2px 2px var(--shadowDark),
  2.5px 2.5px var(--shadowDark),
  3px 3px var(--shadowDark),
  3.5px 3.5px var(--shadowDark),
  4px 4px var(--shadowDark)
`

const SmallerShadowAltStyle = `
  0.5px 0.5px var(--shadowDark),
  1px 1px var(--shadowDark),
  1.5px 1.5px var(--shadowDark),
  2px 2px var(--shadowDark),
  2.5px 2.5px var(--shadowDark)
`

const ReverseShadowStyle = `
  0.5px 0.5px var(--textColor),
  1px 1px var(--textColor),
  1.5px 1.5px var(--textColor),
  2px 2px var(--textColor),
  2.5px 2.5px var(--textColor),
  3px 3px var(--textColor),
  3.5px 3.5px var(--textColor),
  4px 4px var(--textColor)
`

export {
  ShadowStyle,
  ShadowAltStyle,
  IconColor,
  SmallerShadowAltStyle,
  ReverseShadowStyle
}
