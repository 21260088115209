import styled from 'styled-components'

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2rem solid var(--accent);
  /* @media (max-width: 768px) {
    display: none;
  } */
`

export default NavBar
