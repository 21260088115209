import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

function SEO({ title, description }) {
  const metaTitle = !title
    ? 'Mustafa Campwala · Software Engineer'
    : `${title} · Mustafa Campwala`
  const metaDescription = !description
    ? `Hi! My name is Mustafa. I am a software engineer in Dubai passionate about building products that people will enjoy using. ${title ? `| ${title}` : ''}`
    : description
  const url = 'https://mustafazc.com'
  const MyLogo = `${url}/my-icon.png`

  return (
    <Helmet defer={false}>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{metaTitle}</title>
      <html lang="en" />

      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />

      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Mustafa Campwala" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image" content={MyLogo} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={MyLogo} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string
}

export default SEO
