import React, { useState, useEffect } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

import sun from '../assets/img/sun.png'
import moon from '../assets/img/moon.png'

const ThemeToggle = () => {
  const [theme, setTheme] = useState(null)
  const ONCE = []
  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => setTheme(window.__theme)
  }, ONCE)

  return (
    <Toggle
      checked={theme === 'dark'}
      onChange={e =>
        window.__setPreferredTheme(e.target.checked ? 'dark' : 'light')
      }
      aria-label="Theme Toggle"
      icons={{
        checked: (
          <img
            style={{ pointerEvents: 'none' }}
            width="16"
            height="16"
            alt="moon"
            aria-hidden
            src={moon}
          />
        ),
        unchecked: (
          <img
            style={{ pointerEvents: 'none' }}
            width="16"
            height="16"
            alt="sun"
            aria-hidden
            src={sun}
          />
        )
      }}
    />
  )
}

export default ThemeToggle
