import styled from 'styled-components'

const FooterText = styled.p`
  text-transform: uppercase;
  color: var(--colorInverted);
  text-align: center;
  font-size: 0.75rem;
`

export default FooterText
