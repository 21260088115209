import React from 'react'
import PropTypes from 'prop-types'

import SEO from './SEO'
import Footer from './Footer'
import NavBar from './NavBar'
import Layout from '../css/Layout'
import Main from '../css/Main'

const LayoutFunction = ({ children, title, noFooter }) => {
  return (
    <>
      <SEO title={title} />
      <Layout>
        {!noFooter && <NavBar />}
        <Main>{children}</Main>
        {!noFooter && <Footer />}
      </Layout>
    </>
  )
}

export default LayoutFunction

LayoutFunction.propTypes = {
  children: PropTypes.node,
  noFooter: PropTypes.bool,
  title: PropTypes.string
}

// http://ademills.com/news/
