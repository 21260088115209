import React from 'react'
import Footer from '../css/Footer'
import FooterText from '../css/FooterText'

export default function FooterComponent() {
  return (
    <Footer>
      <FooterText>
        Copyright &copy; {new Date().getFullYear()} Mustafa Campwala. All rights
        reserved.
      </FooterText>
    </Footer>
  )
}

// TODO: ADD 971 somewhere
