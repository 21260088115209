import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TweenMax, Power3 } from 'gsap'

import ThemeToggle from './ThemeToggle'
import NavBar from '../css/NavBar'
import NavLink from '../css/NavLink'
import NavOuterDiv from '../css/NavOuterDiv'

export default function NavBarFunction() {
  // let navbar = useRef(null)
  let navbarOuter = useRef(null)

  useEffect(() => {
    // navbar.childNodes.forEach((child, index) => {
    //   if (index > 1) return // Only animates first two children
    //   /*
    //     The plus index / 10 adds a 0.1 second delay for each child based on the current array item
    //     first child will be 0.5 + 0(index) / 10 = 0.5
    //     second child will be 0.5 + 1(index) / 10 = 0.6
    //     It adds the duration of thee first item and the delay of the next item
    //   */
    //   TweenMax.from(child, { y: 200, ease: Power3.easeIn, duration: 0.5 + index / 10, delay: 0 })
    //   TweenMax.to(child, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: 0.5 + index / 10 })
    // })
    navbarOuter.childNodes.forEach((child, index) => {
      TweenMax.from(child, { y: 200, ease: Power3.easeIn, duration: 0.8 + index / 8 })
      TweenMax.to(child, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: index === 0 ? 0.8 : 0.8 + index / 8 })
    })
  }, [])

  return (
    <NavBar>
      <NavOuterDiv ref={el => (navbarOuter = el)}>
        <NavLink to="/" activeClassName="navLinkHover">
          Home
        </NavLink>
        <NavLink to="/about/" activeClassName="navLinkHover">
          About
        </NavLink>
        <NavLink to="/blog/" activeClassName="navLinkHover">
          Blog
        </NavLink>
        <NavLink to="/work/" activeClassName="navLinkHover">
          Work
        </NavLink>
        <div style={{ opacity: 0 }}>
          <ThemeToggle />
        </div>
      </NavOuterDiv>
    </NavBar>
  )
}

NavBarFunction.propTypes = {
  noHome: PropTypes.bool
}
