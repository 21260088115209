import { Link } from 'gatsby'
import styled from 'styled-components'

const NavLink = styled(Link)`
  padding: 1rem 1rem;
  text-transform: capitalize;
  color: var(--colorInverted);
  text-decoration: none;
  font-weight: 700;
  border-bottom: 0.3rem solid transparent;
  opacity: 0;
  :hover {
    border-bottom: 0.3rem solid var(--accent);
  }
`

export default NavLink
