import styled from 'styled-components'

const NavOuterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  text-align: center;
  align-items: center;
`

export default NavOuterDiv
